* {
  box-sizing: border-box;
}
html,
body,
#root,
.global-layout {
  height: 100%;
  background-color: @body-background;
  overflow: hidden;
}
.text-right {
  text-align: right;
}
.text-nowrap {
  white-space: nowrap;
}
.w-100 {
  width: 100%;
}
.bg-white {
  background-color: @white-background;
}
.p-0 {
  padding: 0;
}
