.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  color: @text-color-dark;
  font-size: 20px;
}
.ant-layout-sider-zero-width-trigger {
  top: 11px;
}

.sider-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .tag {
    margin-top: auto;
    margin-bottom: 12px;
    align-self: center;
    color: white;
  }
}
