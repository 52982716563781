.login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  &--box {
    text-align: center;
    &--form {
      min-width: 300px;
      text-align: left;
      &--psw {
        float: right;
      }
    }
  }
}

.otp-modal {
  .step-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;
  }
  .step-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .step-title-step {
      background: #0db2b5;
      color: white;
      width: 32px;
      height: 32px;
      border-radius: 32px;
      line-height: 32px;
      font-size: 16px;
      text-align: center;
      margin-bottom: 8px;
    }
    .step-title-text {
      font-size: 16px;
      line-height: 1.35;
    }
    .step-title-subtext {
      color: rgb(141, 141, 141);
      text-align: center;
    }
  }
  .step1-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      border-radius: 20%;
      background-size: contain;
      width: 100px;
      margin: 15px;
    }
  }

  .step2-content {
    margin-top: 2em;
    .qrcode-box {
      margin-right: 2em;
    }
    .setup-key {
      background-color: #e4e4e4;
      border-radius: 5px;
      padding: 1em;
      font-size: 16px;
    }
  }

  .step3-content {
    margin: 2em 0;
    text-align: center;
  }
}
